import React from 'react';
import _ from 'lodash';
import { graphql, navigate } from 'gatsby';
import Layout from 'components/Layout';
import SEO from 'components/seo';
import News from 'features/media/news';
import PRESS from 'src/utils/pressConstants';
import styles from './media.module.scss';
import MailSubmittedModal from 'components/mailSubmittedModal';
import Alert from 'components/Alert';
import HeroSection from 'components/HeroSection';
import { useContactPoint } from 'shared/queries/contactPoint';
import { generateContentfulAssetURL } from 'utils/image';

const Media = props => {
  const { contactPoint } = useContactPoint();
  const pressContact = props.data.allContentfulWebMediaPressOptions.edges
    .map(edge => edge.node.pressContact)
    .filter(node => node.entries?.length && node)
    .map(entry => entry.entries[0])
    .filter(entry => entry.revision !== 0);
  const { pageContext } = props;
  const { numPages, currentPage } = pageContext;
  const posts = props.data.allContentfulNewsPost.edges;
  const pageData = {
    ...props.data.allContentfulWebMediaPressOptions.edges[0].node,
    pressContact:
      pressContact?.length > 0
        ? _.find(contactPoint, ['contentful_id', pressContact[0].id])
        : {},
  };
  const state = props.location.state || {};

  const seoImage = {
    url: generateContentfulAssetURL(pageData?.heroImage?.image?.file?.url),
    width: pageData?.heroImage?.image?.file?.details?.image?.width,
    height: pageData?.heroImage?.image?.file?.details?.image?.height,
  };

  return (
    <div>
      {state.emailSent === true ? (
        <MailSubmittedModal />
      ) : state.emailSent === false ? (
        <Alert color="danger" msg={state.emailError} />
      ) : (
        ''
      )}
      <Layout transparentHeader={true} paddingTop={false}>
        <SEO title="Media" image={seoImage} />
        <div className={styles.media}>
          <HeroSection
            image={pageData?.heroImage?.image}
            title={pageData?.heroImage?.title}
            subTitle={pageData?.heroImage?.subTitle}
          />
          <News
            posts={posts.map(post => {
              return {
                title: post?.node?.title,
                description: post?.node?.description,
                image: post?.node?.featuredImage?.fluid,
                url: post?.node?.url,
                imageDescription: post?.node?.featuredImage?.description,
              };
            })}
            tab={PRESS.news}
            pageData={pageData?.pressContact}
            pageNumber={currentPage}
            totalNumberOfPages={numPages}
            changePage={page => {
              navigate(
                page === 1 ? `/${PRESS.newsUrl}/` : `/${PRESS.newsUrl}/${page}`,
                {
                  state: { internal: true },
                }
              );
            }}
            location={props.location}
          />
        </div>
      </Layout>
    </div>
  );
};
export default Media;

export const query = graphql`
  query getNews($skip: Int!, $limit: Int!) {
    allContentfulNewsPost(
      sort: { fields: createdAt, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          url
          title
          description
          featuredImage {
            description
            fluid(maxWidth: 1800, maxHeight: 620, quality: 90) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
    allContentfulWebMediaPressOptions {
      edges {
        node {
          title
          contentful_id
          heroImage {
            title
            subTitle
            image {
              fluid(maxWidth: 1800, quality: 100) {
                ...GatsbyContentfulFluid_withWebp
              }
              description
              file {
                url
                details {
                  image {
                    height
                    width
                  }
                }
              }
            }
          }
          pressContact {
            ... on contentfulWebMediaPressOptionsPressContactJsonNode {
              entries {
                description
                id
                revision
                title
              }
            }
          }
        }
      }
    }
  }
`;
